import React from "react";
import { Fade } from "react-reveal";
import "./Greeting.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import {greeting } from "../../portfolio";


export default function Greeting() {
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
            <h1 className="greeting-text">
              Hi, I'm <br />
              Alexis DiNardo
            </h1>
              <p className="greeting-text-p subTitle">
                {greeting.subTitle}
              </p>
              <SocialMedia />
              <div className="button-greeting-div">
                {greeting.resumeLink && (
                  <a
                  href={"https://drive.google.com/file/d/1j6lKyGt7qj-wTvPvWCBG3TP-YgZRS2UH/view?usp=sharing"} // Assuming your resume is located here
                  target="_blank" // Open in new tab/window
                  rel="noreferrer"
                  className="download-link-button"
                  >
                    <Button text="Download my resume" />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
          <img
              alt="man sitting on table"
              src={require("../../assets/images/profile.JPG")}
            ></img>
          </div>
        </div>
      </div>
    </Fade>
  );
}
